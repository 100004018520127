.MuiDrawer-paperAnchorLeft, .MuiDrawer-paperAnchorRight {
  background-color: #E7B33F !important;
}

.nav-drawer-open .MuiDrawer-paperAnchorLeft, .nav-drawer-open .MuiDrawer-paperAnchorRight {
  background-color: #065D7E !important;
}

.MuiButtonBase-root {
	margin-right: 16px !important;
}

.nav-drawer-open .MuiDrawer-paper {
  width: 100% !important;
}

.drawer__open-button:hover, .drawer__scroll-to-top-button:hover {
  background-color: unset !important;
  color: unset !important;
}

#slider {
  position: absolute;
  transform: translateY(-126px);
  -webkit-transform: translateY(-126px);
}

/* .slide-start {
    Not currently used
} */

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateY(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateY(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateY(0%); }
  100% { transform: translateY(-126px); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateY(0%); }
  100% { -webkit-transform: translateY(-126px); }
}

@media (max-width: 600px) {
  .MuiDrawer-paperAnchorLeft, .MuiDrawer-paperAnchorRight {
    background-color: #065D7E !important;
  }
}
