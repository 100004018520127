.employee-profile-image {
  max-height: 300px !important;
  max-width: 300px !important;
}

@media (min-width: 600px) {
  /* All but XS */
  .employee-grid-image:hover {
    border-top-left-radius: 30px 30px;
    border: 5px solid white;
  }
}
