.media-link-arrow {

}

@media (min-width: 1537px) {
/* XL */
  .media-link-arrow {

  }

}
@media (max-width: 1536px) {
/* LG */
  .media-link-arrow {

  }
}
@media (max-width: 1200px) {
/* MD */
  .media-link-arrow {
    height: 30px;
  }
}
@media (max-width: 900px) {
/*SM */
  .media-link-arrow {
    height: 28px;
  }
}
@media (max-width: 600px) {
/* XS */
  .media-link-arrow {
    height: 24px;
  }
}
