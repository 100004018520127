@font-face {
  font-family: Sharp Sans;
  font-weight: 300;
  src: url("./fonts/SharpSans-Book.woff2");
}

@font-face {
  font-family: Sharp Sans;
  font-weight: 500;
  src: url("./fonts/SharpSans-Medium.woff2");
}

@font-face {
  font-family: Sharp Sans;
  font-weight: 600;
  src: url("./fonts/SharpSans-Semibold.woff2");
}

@font-face {
  font-family: Sharp Sans;
  font-weight: 700;
  src: url("./fonts/SharpSans-Bold.woff2");
}

@font-face {
  font-family: "CooperBTMedium";
  src: url("./fonts/webFonts/CooperBTMedium/font.woff2") format('woff2'), url("./fonts/webFonts/CooperBTMedium/font.woff") format('woff');
  font-weight: 400;
}

@font-face {
  font-family: "CooperBTMedium";
  src: url("./fonts/webFonts/CooperBTMedium/font.woff2") format('woff2'), url("./fonts/webFonts/CooperBTMedium/font.woff") format('woff');
  font-weight: 500;
}

@font-face {
  font-family: "CooperBTMedium";
  src: url("./fonts/webFonts/CooperBTMedium/font.woff2") format('woff2'), url("./fonts/webFonts/CooperBTMedium/font.woff") format('woff');
  font-weight: 600;
}

@font-face {
  font-family: "cooper-black-std";
  src: url("https://use.typekit.net/af/4e1b68/000000000000000000013101/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4e1b68/000000000000000000013101/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4e1b68/000000000000000000013101/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "cooper-black-std";
  src: url("https://use.typekit.net/af/9044bf/000000000000000000013102/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/9044bf/000000000000000000013102/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/9044bf/000000000000000000013102/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: '#303836';
  background-color: #F4F2ED !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* These are used for styling images on the Programs and Products Page  */
.responsive-image {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.responsive-image-vertical {
  width: auto;
  height: 100%;
  padding: 12px 0;
  margin: 0;
}

.program-background {
  border: 1px solid red;
  height: 100%;
  width: auto;
  overflow: hidden;
  object-fit: cover;  
}


/* Remove this from error page and then delete  */
.main-block {
  padding-left: 140px;
}